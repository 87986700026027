import { Component, DestroyRef, effect, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl } from '@angular/forms';
import { PrivateChatApiService } from '@dc-api/private-chat.api.service';
import { ChatConversation, ChatParticipant } from '@dc-core/dc-backend/dc-classes';
import { MenuController, ModalController, NavController } from '@ionic/angular/standalone';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { AuthService } from '@services/auth.service';
import { debounceTime } from 'rxjs';
import { ChatsStartChatDialogComponent } from 'src/app/modules/chats/components/chats-start-chat-dialog/chats-start-chat-dialog.component';
import { environment } from 'src/environments/environment';
import { addIcons } from "ionicons";
import { searchOutline } from "ionicons/icons";

@Component({
    selector: 'app-private-chats',
    templateUrl: 'private-chats.component.html',
})
export class PrivateChatsComponent implements OnInit {
    public auth: AuthService = inject(AuthService);

    private ga: DartCounterAnalyticsService = inject(DartCounterAnalyticsService);
    private nav: NavController = inject(NavController);
    private modalController: ModalController = inject(ModalController);
    private menuController: MenuController = inject(MenuController);
    private privateChatApiService: PrivateChatApiService = inject(PrivateChatApiService);

    public isLoaded = false;
    public participants: ChatParticipant[] = [];
    public searchControl: UntypedFormControl = new UntypedFormControl();
    public searchString: string = '';

    private destroyRef: DestroyRef = inject(DestroyRef);

    constructor() {
        effect(() => {
            if (this.privateChatApiService.unreadCount()) {
                this.getPrivateChats();
            }
        });
        addIcons({ searchOutline });
    }

    public ionViewWillEnter(): void {
        this.ga.trackView('Private chats');

        this.menuController.enable(true, 'mainmenu');
        this.menuController.enable(false, 'ingamemenu');

        this.getPrivateChats();
    }

    public ngOnInit(): void {
        this.searchControl.valueChanges
            .pipe(debounceTime(600), takeUntilDestroyed(this.destroyRef))
            .subscribe((value) => {
                this.searchString = value;
            });
    }

    public startNewChat(): void {
        this.modalController
            .create({
                component: ChatsStartChatDialogComponent,
                backdropDismiss: false,
                showBackdrop: true,
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    const chat: ChatConversation = dialogRes.data;
                    if (chat) {
                        this.openChat(chat);
                        this.getPrivateChats();
                    }
                });
            });
    }

    public onChatClick(participant: ChatParticipant): void {
        this.privateChatApiService.unreadCount.update((unreadCount) => unreadCount - participant.unread_count);
        this.openChat(participant.conversation);
    }

    public openChat(conversation: ChatConversation): void {
        this.nav.navigateForward('chats/' + conversation.id);
    }

    private getPrivateChats(): void {
        this.privateChatApiService
            .getPrivateChats({})
            .then((res) => {
                const participants: ChatParticipant[] = [];
                res.data.data.forEach((participant) => {
                    if (
                        !participant.conversation.direct_message ||
                        (participant.conversation.direct_message && participant.conversation.has_messages)
                    ) {
                        participant.conversation.participants = participant.conversation.participants.filter(
                            (participantFromList) => participantFromList.user_id !== this.auth.user.id
                        );

                        if (participant.conversation.direct_message) {
                            participant.conversation.name = participant.conversation.participants[0].user.full_name;
                        }

                        participants.push(participant);
                    }
                });

                this.participants = participants;
            })
            .finally(() => {
                this.isLoaded = true;
            });
    }
}
