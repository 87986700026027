import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { PopoverController } from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';

export type ChatsConversationOptionsPopoverResponse = 'profile' | 'report' | 'delete';

@Component({
    selector: 'app-chats-conversation-options-popover',
    templateUrl: './chats-conversation-options-popover.component.html',
    standalone: true,
    imports: [CommonModule, TranslateModule],
})
export class ChatsConversationOptionsPopoverComponent {
    private popoverController: PopoverController = inject(PopoverController);

    closePopover(res: ChatsConversationOptionsPopoverResponse = null): void {
        this.popoverController.dismiss(res);
    }
}
